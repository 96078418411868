import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Album, { Img } from '../components/Image';
import { FaWindows, FaApple, FaLinux, FaCheck, FaCheckCircle } from 'react-icons/fa';
import styles from '../css/products.module.css';
import { capitalise } from '../utils/prototype';
import { isMobile } from '../utils/auth';

const Features = {
	pairings: {
		name: 'Your pairings table',
		items: [
			'Automatic results pulling for every online game',
			'Links to every online game and PGN downloads',
			'Individual tables for team tournaments',
			'Automatic dates and times for every online game',
			'Filter pairings by completion'
		]
	},
	results: {
		name: 'Keeping your players informed',
		items: [
			'Inline rules display, with links to each page',
			'Custom announcements, with website and email notifications',
			'Descriptions and tournament \'statuses\'',
			'Tie breaks',
			'Time control information',
			'Alerts from outstanding and upcoming games'
		]
	},
	enhancements: {
		name: 'Ways to enhance your tournaments',
		items: [
			'Your profile picture',
			'Link to your website',
			'In-built live Twitch streaming, watch while you browse',
			'Personalised background with colours generate from your logo',
			'Federation flags',
			'Aesthetic results completion graphs, statistics',
			'Support for multiple languages'
		]
	},
	profiles: [
		'Individual player profiles',
		'Individual team profiles',
		'With match histories',
		'Customisable bios',
		'Contact details from Facebook, Twitter, Lichess, Chess.com, and email',
		'Dark mode',
		'Custom backgrounds'
	],
	standings: {
		name: 'Your standings table',
		items: [
			'Support for game points and net game points',
			'Displays Win / Draw / Loss / Played statistics',
			'Win percentages',
			'Direct links to each match-up',
			'Beautiful stats page showing rankings over time'
		]
	}
};

export default function ScorchApp() {

	return <>
		<section id='results-about'>
			<div className='wrapper'>
				<a data-aos='fade' href='#about'>
					<h1>ScorchResults</h1>
				</a>
				<div data-aos='fade' className={styles.description}>
					<p>ScorchResults is for the players. Navigate pairings, standings, stats, teams, individual score trackers and so much more.</p>
					<p>ScorchResults possesses several cutting-edge features. It ‘syncs’ – rather than having to chase up and type, results are automatically ‘pulled’ from online games. There is also in-built support for live commentary, beautiful standings tables, and stats. This has helped to foster tournament ‘communities’ who gather to follow live games.</p>
					<p>We make it so that you don't 'need' a website to make a tournament, and if you have one, all our features embeddable. We create <a href='https://results.scorchapp.co.uk/t/dda223d/standings/4'>beautiful tournament pages</a> that you can show off to your players. Everything you need to get started.</p>
				</div>
				<div className='buttonSection'>
					<a
						data-aos='zoom-in'
						className={cx('web', 'download', styles.download)}
						href='https://web.scorchapp.co.uk/'
						target='_blank'
						rel='noopener noreferrer'
					>
						Visit results site
					</a>
				</div>
			</div>
		</section>
		<section id='results-features'>
			<div className='wrapper'>
				<h1 data-aos='fade'>Filled with specialised features to manage any tournament, league, or congress</h1>
				<p><b>Ingenuity</b> - ScorchChess offers all the features imaginable and lets both organisers and players alike know how to use them. Our layouts are simpple and beautiful, yet whenever you need a feature, you know exactly where to click and create. We hope this allows a new focus on enjoying the game.</p>
				<div className={cx(styles.grid, styles.results)}>
					{Object.entries(Features).map(([k, list], i, arr) => {
						let name = capitalise(k);
						if (!Array.isArray(list)) {
							name = list.name;
							list = list.items;
						}
						return <div
							id={k}
							key={k}
							className={cx(styles.box, styles.r)}
							style={{gridArea: k}}
							data-aos={'fade-' + (isMobile() || i < arr.length / 2 ? 'right' : 'left')}
						>
							<a href={'#' + k} className={styles.title}>
								{name}
							</a>
							{list.map((elem) => {
								return <>
									<FaCheckCircle />
									<div>{elem}</div>
								</>;
							})}
						</div>;
					})}
				</div>
				<div>
					<label>
						See <Link to='/desktop#features'>ScorchApp</Link> for more features about managing your tournaments.
					</label>
				</div>
			</div>
		</section>
		<section id='views' className={styles.view}>
			<div className='wrapper'>
				<h1 data-aos='fade'>ScorchResults views</h1>
				<div className='columns'>
					<div className='column-text'>
						<h3 data-aos='fade'>Your tournament dashboard</h3>
						<p data-aos='fade-right'>Filled with information for your players, and still aesthetic.</p>
						<p data-aos='fade-right'>Contains useful links to your website, rules, announcements, etc.</p>
					</div>
					<div data-aos='fade'>
						<Img src='/screenshots/ScorchResults/dashboard.jpg' />
					</div>
				</div>
			</div>
		</section>
		<section id='knockout' className={styles.view}>
			<div className='wrapper'>
				<div className='columns'>
					<div data-aos='fade'>
						<Img src='/screenshots/ScorchResults/Knockout2.jpg' />
						<Img src='/screenshots/ScorchResults/Knockout.jpg' />
					</div>
					<div className='column-text'>
						<h3 data-aos='fade'>One-of-a-kind knockout view</h3>
						<p data-aos='fade-right'>Generate beautiful knockout brackets. Can your current tournament software do that?</p>
						<p data-aos='fade-right'>We also have functionality for knockout brackets within poools, division, playoffs which don't have second rounds, and starting a knockout 'finals' halfway through a tournament. You name it, we can do it.</p>
					</div>
				</div>
			</div>
		</section>
		<section id='pairings-table' className={styles.view}>
			<div className='wrapper'>
				<div className='columns'>
					<div className='column-text'>
						<h3 data-aos='fade'>Your pairings table</h3>
						<p data-aos='fade-right'>Another highly intuitive layout that contains your pairings and results, teams, completion rate displayed in our iconic "rainbow" arc, and support for many features such such as downloading pairings, filtering, searching, and jumping to your pairing.</p>
					</div>
					<div data-aos='fade'>
						<Img src='/screenshots/ScorchResults/Pairings.jpg' />
					</div>
				</div>
			</div>
		</section>
		<section id='standings-table' className={styles.view}>
			<div className='wrapper'>
				<div className='columns'>
					<div data-aos='fade'>
						<Img src='/screenshots/ScorchResults/Standings.jpg' />
					</div>
					<div className='column-text'>
						<h3 data-aos='fade-left'>Your standings table</h3>
						<p data-aos='fade-left'>All your teams and players, right with all the information you could need with them. Has support for pools (divisions) within the tournament.</p>
					</div>
				</div>
			</div>
		</section>
		<section id='finals' className={styles.view}>
			<div className='wrapper'>
				<div className='columns'>
					<div className='column-text'>
						<h3 data-aos='fade'>Your finished tournaments</h3>
						<p data-aos='fade-right'>We took the liberty of adding a nice 'Final Scores' view for when you finish the tournament. We know it's the little things that count.</p>
					</div>
					<div data-aos='fade'>
						<Img src='/screenshots/ScorchResults/Finals.jpg' />
					</div>
				</div>
			</div>
		</section>
		<section id='stats' className={styles.view} data-aos-offset={-100}>
			<div className='wrapper'>
				<div className='columns'>
					<div data-aos='fade'>
						<Album
							type='grid'
							enableLightbox
							photos={(() => {
								let x = [] as string[];
								for (let i = 1; i < 5; i++) {
									x.push(`/screenshots/ScorchResults/Stats${i.toString()}.jpg`);
								}
								return x;
							})()}
							ratios={[
								[1353, 881],
								[1278, 647],
								[1417, 900],
								[859, 844]
							]}
							interval={5000}
							data-aos='zoom-in'
						/>
					</div>
					<div className='column-text'>
						<h3 data-aos='fade'>Statistics</h3>
						<p data-aos='fade-right'>Finally, we show off our afamed 'stats' pages. Made with the latest in modern web technologies such as <a href='https://gionkunz.github.io/chartist-js/' target='_blank' rel='noopener noreferrer'>chartist.js</a>, view your player standings over time in a stunning visual manner. Also, generate amazing graphs by seeing Match points over time, Game Point differences, and percentages.</p>
						<p data-aos='fade-right'>Get every single one of our features, without compromise, when you register for one of our <Link to='/pricing'>licensed plans</Link>.</p>
					</div>
				</div>
			</div>
		</section>
	</>;
}