import React, { ReactElement, useEffect, useState, Component } from 'react';
import { StaticContext } from 'react-router';
import { BrowserRouter as Router, Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import './App.css';
import Titlebar from './components/Titlebar';
import Footer from './components/Footer';
import { Home, Pricing, FAQ, About, Embed, ScorchApp, ScorchResults } from './pages';
import AOS from 'aos';

import 'aos/dist/aos.css';
import './css/Carousel.css';
AOS.init();

export function scrollToID(id: string) {
	let body = document.getElementsByTagName('BODY')[0];
	if (!body) return;
	let element = document.getElementById(id.toLowerCase()) as HTMLDivElement;
	if (!element) return;
	window.scrollTo({
		top: element.offsetTop - (window.innerHeight * 0.12),
		left: 0,
		behavior: 'smooth'
	});
}

const routes = ['desktop', 'results', 'Pricing', 'About'];
const components = {
	home: Home,
	desktop: ScorchApp,
	results: ScorchResults,
	pricing: Pricing,
	about: About
} as {[key: string]: (props?: RouteComponentProps<any, StaticContext, any>) => ReactElement};

function App(): ReactElement {

	let hash = window.location.hash.slice(1);
	const [id, setID] = useState(hash);

	useEffect(() => {
		scrollToID(id);
	}, [id]);

	useEffect(() => {
		window.addEventListener('load', AOS.refresh);
		return () => window.removeEventListener('load', AOS.refresh);
	}, []);

	return <>
		<Router basename={process.env.PUBLIC_URL}>
			<Titlebar setID={setID} routes={routes} />
			<div className='background-gradient' />
			<div className='background' />
			<div className='main'>
				<Route render={({location}) => {
					window.scrollTo(0, 0);
					return (
						<Switch location={location}>
							<Route exact path='/' component={Home} />
							<Route exact path='/embed-example' component={Embed} />
							{routes.map((r) => {
								return <Route key={r} exact path={'/' + r.toLowerCase()} render={(props) => {
									let Elem = components[r.toLowerCase()];
									return <Elem {...props} />;
								}} />;
							})}
							<Route path='*' render={() => null} status={404} />
						</Switch>
					);
				}}/>          
				<Footer />
			</div>
		</Router> 
	</>;
}

export default App;
