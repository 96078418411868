import React, { ReactElement } from 'react';
import cx from 'classnames';
import styles from '../css/about.module.css';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { signature } from './signature';

export default function About(): ReactElement {

	return <>
		<section id='story'>
			<div className='wrapper'>
				<a data-aos='fade' href='#story'>
					<h1>Story</h1>
				</a>
				<div className='quote'>
					<FaQuoteLeft data-aos='fade' className={styles.leftQuote} />
					<p data-aos='fade-right'>When I first joined my club at uni, we tried to resurrect the annual amateur rapid-play tournament. Picture it – around 100 anxious undergraduates, keen to return back to their revision notes, stuck inside on a hot summer’s day. All because they were keen chess players attending their one outlet to play over the course of the term.<br />
					The competition was nearly derailed by slow pairing times. We’d scoured the web and couldn’t find a single program that could handle individual players, teams, and a Swiss system that was accessible to use.</p>
					<p data-aos='fade-right'>There is so much technology available to us and it is all so powerful. Those kind enough to organise amazing playing opportunities should never have to be bogged down in logistics that distract from the incredible game of chess.</p>
					<p data-aos='fade-right'><b>ScorchChess</b> was built during the UK lockdown from January to April 2020. It is a software that takes care of all the arduous management. Originally envisioned for in-person tournaments, it grew rapidly to cater for the community transitioning online and developed ground-breaking integrations with Lichess.org, Chess.com, and ECF online services. It modernises organisation and makes the process easy and enjoyable. The platform is intuitive, filled with features, and innovative. Chess players shouldn't have to settle for anything less.</p>
					<FaQuoteRight data-aos='fade' className={styles.rightQuote} />
				</div>
				<img data-aos='fade' className={styles.signature} src={signature} alt='signature' />
			</div>
		</section>
		<section id='core-values'>
			<div className='wrapper'>
				<a data-aos='fade' href='#core-values' className={styles.title}>
					<h1>Core Values</h1>
				</a>
				<div className={cx('columns', styles.columns)}>
					<div data-aos='fade-down' data-aos-delay='250' id='efficiency'>
						<h3>Efficiency</h3>
						<p>Everything that can be automated is so. ScorchChess opposes wasting time and energy for the tournament organisers and players it services.</p>
						<p>We create things to be built once and work forever, permitting true enjoyment of chess and organisation.</p>
					</div>
					<div data-aos='fade-down' data-aos-delay='500' id='ingenuity'>
						<h3>Ingenuity</h3>
						<p>ScorchChess promotes the use of technology to its fullest potential at all times. Only by removing all the mundane tasks involved that we're forced to do on a daily basis can innovation be achieved.</p>
						<p>ScorchChess aims to be on the cutting edge of all its endeavours.</p>
					</div>
					<div data-aos='fade-down' data-aos-delay='750' id='inclusivity'>
						<h3>Inclusivity</h3>
						<p>ScorchChess seeks to grow the chess community wherever possible and make running tournaments accessible to non-professionals.</p>
						<p>It is further committed to upholding social justice wherever possible throughout its activities.</p>
					</div>
				</div>
			</div>
		</section>
		<section id='author'>
			<div className='wrapper'>
				<a data-aos='fade' href='#author' className={styles.title}>
					<h1>About the author</h1>
				</a>
				<div>
					<p data-aos='fade-left'>Scorch is the work of Aloysius Lip, a 2nd-year undergraduate student at the University of Oxford and the current President of the Oxford University Chess Club. He reads Philosophy, Politics, and Economics, specialising in Microeconomics, Public Economics, and Game Theory.</p>
					<p data-aos='fade-left'>He enjoys programming in his free time, having won the international coding competition in 2018. His previous projects in the world of chess development, viewable on his <a href='https://aloysiuslip.com' rel='noopener noreferrer' target='_blank'>personal website</a>, have included:</p>
					<table data-aos='fade'>
						<tbody>
							<tr>
								<th><a href='http://users.ox.ac.uk/~chess' target='_blank' rel='noopener noreferrer'>
									Oxford University Chess Club
								</a></th>
								<td>The official website for the university society</td>
							</tr>
							<tr>
								<th><a href='https://npmjs.com/package/ecf' target='_blank' rel='noopener noreferrer'>
									ECF.JS
								</a></th>
								<td>The JavaScript wrapper for the ECF grading database that makes it really easy to download rating data from the ECF for any player or club</td>
							</tr>
							<tr>
								<th><a href='https://npmjs.com/package/lichess' target='_blank' rel='noopener noreferrer'>
									Lichess.JS
								</a></th>
								<td>The JavaScript wrapper for the Lichess API</td>
							</tr>
							<tr>
								<th><a href='https://npmjs.com/package/lichess' target='_blank' rel='noopener noreferrer'>
									LAZYbot
								</a></th>
								<td>A Discord bot designed for chess servers</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</section>
		<section id='development' className={styles.container}>
			<div data-aos='fade' className='wrapper'>
				<a href='#development' className={styles.title}>
					<h1>Development</h1>
				</a>
				<div>
					<p data-aos='fade-left'>A full-stack developer, Aloysius has designed every aspect of this product, from server to database to mobile to domain and storage management to distribution. His preferred speciality is in <span>front-end</span> development. He works in React.JS, the framework designed by Facebook and enjoys making all his work picture perfect, to optimise it for all the chess players in the world who might come along and use his work.</p>
					<p data-aos='fade'>With additional thanks to the following:
						<ul>
							<li>Peter Hornsby, tester</li>
							<li>James Connors, tester</li>
							<li>Aron Teh, dev-in-training</li>
						</ul>
					</p>
				</div>
			</div>
		</section>
		<section id='contact' className={styles.container}>
			<div className='wrapper'>
				<a data-aos='fade' href='#contact' className={styles.title}>
					<h1>Contact</h1>
				</a>
				<div>
					<p data-aos='fade-left'>Leave any feedback from the beta deployment of <span className='highlighted'>Scorch</span> on <a className='inlineLink' href='https://github.com/Oxford-Chess-Development/toastie-desktop-build/issues' target='_blank' rel='noopener noreferrer'>GitHub</a>. It's super easy to make a GitHub account and you can track the progress on your requests as they're handled!</p>
					<p data-aos='fade-left'>Alternatively, Aloysius is reachable at any of the below links.</p>
				</div>
			</div>
		</section>
	</>;
}