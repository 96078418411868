import React from 'react';
import cx from 'classnames';
import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { ReactElement } from 'react';
import styles from '../css/footer.module.css';
import { IconType } from 'react-icons/lib';
import { isMobile } from '../utils/auth';

const links = {
	facebook: {
		href: 'https://facebook.com/scorchchess',
		icon: FaFacebook
	},
	twitter: {
		href: 'https://twitter.com/ChessOxford',
		icon: FaTwitter
	},
	website: {
		href: 'https://results.scorchapp.co.uk',
		icon: '/icons/website.png'
	},
	desktop: {
		href: 'https://web.scorchapp.co.uk/',
		icon: '/icons/desktop.png'
	},
	mobile: {
		href: 'https://www.figma.com/file/74z1QM0ZdtdF2TrKiiAiFj/toastie-mobile',
		icon: '/icons/mobile.png',
		hide: true
	},
	email: {
		href: 'mailto:aloysius.lip@gmail.com',
		icon: '/icons/email.png'
	}
} as {
	[key: string]: {
		href: string
		icon: string | IconType
		hide?: boolean
	}
};

export default function Footer(): ReactElement {
	
	return (
		<div className={styles.footer}>
			<div className={styles.row1}>
				<div className={styles.icons}>
					{Object.entries(links).map(([k, meta], i) => {
						if (meta.hide) return null;
						return (
							<a data-aos='zoom-in' data-aos-delay={100 * i} data-aos-offset={-50}
								key={k} id={k} href={meta.href} target='_blank' rel='noopener noreferrer'>
								{typeof meta.icon === 'string' ?
									<img src={meta.icon} alt={k} /> :
									<meta.icon />
								}
							</a>
						);
					})}
				</div>
			</div>
			<div className={styles.author} data-aos='zoom-in' data-aos-offset={-50}>Copyright 2020, Aloysius Lip</div>
		</div>
	);
}