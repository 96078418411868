import React from 'react';
import cx from 'classnames';
import styles from '../css/reviews.module.css';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { isMobile } from '../utils/auth';

const Quotes = {
	jimmy: {
		body: <>
			<p>As a chess tournaments' organiser and a chess player myself, I have tried several platforms that are sold as “the best” for tournament organisation, management, and publicity. I was never satisfied with any of them and always went back to the basics. Then I found this project and I have to say I have never seen such quality in a tournament management platform.</p>
			<p>Connectivity to the main playing platforms, with FIDE, amazing interface, the developer's assistance is insanely quick and effective... I recommended this amazing platform to my federation and I have no doubt that it will change the dynamics in tournament management.</p>
		</>,
		author: 'Jimmy Barroso\nOrganiser of Cuban Chess Olympiad',
		direction: 'right'
	},
	peter: {
		body: <>
			<p>I was extremely lucky to be able to use Scorch and nearly missed the opportunity. That would have been a massive mistake so I urge everyone else not to make it.</p>
			<p>A different league to anything else out there, and the potential for further development is frighteningly exciting, really look forward to be following its journey and using the platform along the way!</p>
		</>,
		author: 'Peter Hornsby\nFounder, Tournament Director of 2020Chess',
		direction: 'left'
	},
	dan: {
		body: <p>
			Incredibly intuitive and very user friendly, you don't need to be a computer expert to use it.
		</p>,
		author: 'Daniel Hardman\nTreasurer of British Universities\' Chess Association',
		direction: 'left'
	},
	christian: {
		body: <>
			<p>ScorchChess is aesthetic, intuitive and well-integrated across platforms. It is full of handy features that make it easy to tailor tournaments to the needs of the organiser and is miles ahead of its competitors.</p>
			<p>A highlight is the integration with Chess.com and Lichess. ScorchChess addresses the shortcomings of tournament and pairing features on both sites, providing exciting and brand-new opportunities for tournament organisers.</p>
		</>,
		author: 'Christian Schiøler Hansen\nPresident of LSE Chess Club',
		direction: 'right'
	},
	james: {
		body: <>
			<p>ScorchChess is a very user-friendly tool. The large variety of features makes it ideal for any club. It appeals strongly to both a tournament organiser and to participants!</p>
			<p>As a team captain, I have found the results interface to be incredibly visually appealing with lots of information making it ideal.</p>
			<p>It’s my favourite chess online tool, At a time like this, ScorchChess is exactly what we've all needed!</p>
		</>,
		author: 'James Connors\nTreasurer, Captain of Reading University Chess Club',
		direction: 'left'
	}
};

interface ReviewProps {

}
export default function Reviews(props: ReviewProps) {

	return <section id='reviews'>
		<div className={cx('wrapper', styles.wrapper)}>
			<div data-aos='fade' className={styles.title}>
				<h1>Reviews</h1>
			</div>
			{Object.entries(Quotes).map(([k, meta]) => {
				return <div
					id={k}
					key={k}
					className={styles.review}
					style={{gridArea: k}}
					data-aos={'fade-' + (isMobile() ? 'right' : meta.direction)}
				>
					<FaQuoteLeft className={styles.leftQuote} />
					{meta.body}
					<FaQuoteRight className={styles.rightQuote} />
					<p className={styles.author}>{meta.author}</p>
				</div>;
			})}
		</div>
	</section>;
}