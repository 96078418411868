import React from 'react';

interface EmbedProps {

}

export default function Embed(props: EmbedProps) {

	return (
		<div style={{ display: 'flex', justifyContent: 'center', padding: '20px', boxSizing: 'border-box' }}>
			<iframe
				src='https://results.scorchapp.co.uk/t/c9b0f58/standings/9?embed=knockout'
				height={720}
				width={960}
			/>
		</div>
	);
}