import React, { ReactElement } from 'react';
import { capitalise } from '../utils/prototype';
import styles from '../css/home.module.css';
import { isMobile } from '../utils/auth';
import Name from '../components/Name';
import Album from '../components/Image';
import Reviews from './Reviews';

let OSName = 'unknown';
if (navigator.appVersion.includes('Win')) OSName = 'windows';
if (navigator.appVersion.includes('Mac')) OSName = 'mac';
if (navigator.appVersion.includes('X11')) OSName = 'unknown'; //'unix';
if (navigator.appVersion.includes('Linux')) OSName = 'ubuntu';

export default function Home(): ReactElement {

	return <>
		<section id='section-home' className={styles.container}>
			<div className='wrapper'>
				<Name />
				<div>
					<h1>A modern platform for chess tournaments.</h1>
				</div>
				<div className={styles.description} data-aos='zoom-in'>
					Organising chess tournaments should be easy, intuitive, and individually tailored to you.
					<br />Synced across desktop, web, and mobile, <span className='bold'>scorchchess</span> offers everything to run chess tournaments your way. We provide the most effortless experience for yourself, your players, and your spectators.
				</div>
				<div className={'buttonSection'}>
					{!isMobile() && OSName !== 'unknown' ?
						<a data-aos='zoom-in' data-aos-delay='250' className={['platform', 'download'].join(' ')} href={`http://repo.scorchapp.co.uk/latest/${OSName}.html`} target='_blank' rel='noopener noreferrer'>
							Download for {capitalise(OSName === 'ubuntu' ? 'linux' : OSName)}
						</a> :
						null
					}
					{!isMobile() ? <a data-aos='zoom-in' data-aos-delay='500' className={['web', 'download'].join(' ')} href='https://web.scorchapp.co.uk/' target='_blank' rel='noopener noreferrer'>
						Open Web App
					</a> : null}
				</div>
				<div style={{position: 'fixed', visibility: 'hidden'}}>
					<a href='/products#app'>Your scoring manager of choice.</a>
					<a href='/products#results'>Your scoreboard of choice.</a>
					<a href='/products#server'>Your scoring system of choice.</a>
					<a href='/products#mobile'>Your score feed of choice.</a>
				</div>
			</div>	
		</section>
		<section id='awards' className={styles.container}>
			<div className='wrapper'>
				<h1 data-aos='fade'>Award-winning software, recognised by the English Chess Federation</h1>
				<div className='columns'>
					<div className='column-text'>
						<p data-aos='fade-right'>In August 2020, we received the award from the ECF, our national chess federation, for 'Online Contribution of the Year'. This acknowledges chess contributions in the changing digital world.</p>
						<p data-aos='fade-right'>It is extremely gratifying to be recognised and a testatement to the strength of the product we have built. We received this award only 4 months after launch.</p>
						<p data-aos='fade-right'>The award also serves as a reflection on our community and the satisfaction we have received from tournament organisers and players alike. We received nine separate nominations from fans of ScorchChess, including the following citation document.</p>
						<a data-aos='fade-right' href='https://www.englishchess.org.uk/ecf-awards-2020-citations/' target='blank' rel='noopener noreferrer'>
							ECF announcement
						</a>
					</div>
					<Album
						type='carousel'
						enableLightbox={true}
						photos={(() => {
							let x = [] as string[];
							for (let i = 1; i < 19; i++) {
								let n = i.toString();
								x.push(`/ECF/${'0'.repeat(4 - n.length)}${n}.jpg`);
							}
							return x;
						})()}
						ratio={[9, 16]}
						interval={5000}
						maxWidth={'500px'}
						data-aos='zoom-in'
					/>
				</div>
			</div>
		</section>
		<Reviews />
	</>;
}