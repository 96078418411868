import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Album, { Img } from '../components/Image';
import { FaWindows, FaApple, FaLinux, FaCheck, FaCheckCircle } from 'react-icons/fa';
import styles from '../css/products.module.css';
import { capitalise } from '../utils/prototype';
import { isMobile } from '../utils/auth';

const Features = {
	pairings: [
		'Swiss system pairings',
		'Round-robin system pairings',
		'Knockout pairings',
		'Any combination of the above',
		'Pairing pools (divisions)',
		'Manual pairings',
		'Switching pairings',
		'Forbidding specific pairings',
		'Match point and game point totals',
		'Filtering pairings for large tournaments'
	],
	'tie-Breaks': [
		'Head-to-Head',
		'Net game point',
		'Game point total',
		'Most wins',
		'Performance rating',
		'Progressive score',
		'Times played black',
		'Rating',
		'Average rating of opponents cut'
	],
	results: [
		'Games and PGNs automatically pulled from Chess.com',
		'Results automatically entered from Chess.com',
		'Games and PGNs automatically pulled from Lichess.org',
		'Results automatically entered from Lichess.org',
		'Correcting results at any time',
		'Bonus points and disqualifications',
		'Custom win / draw / loss / bye points'
	],
	players: [
		'Individual tournaments',
		'Team tournaments',
		'Adding players from a database',
		'Inputting players/teams from a spreadsheet',
		'Checking for duplicate players',
		'Adding players who join halfway',
		'Removing players who leave',
		'Ratings and performance ratings',
		'Expected scores based on ratings',
		'ECF, FIDE, or ELO rating systems',
		'Player \'pausing\' - inactive players',
		'Searching players for big tournaments'
	]
};

export default function ScorchApp() {

	return <>
		<section id='app'>
			<div className='wrapper'>
				<a data-aos='fade' href='#about'>
					<h1>ScorchApp</h1>
				</a>
				<div data-aos='fade' className={styles.description}>
					<p>ScorchApp is the ‘director’ part of the software. We believe in a separation between 'what the organisers see' and 'what the players see'. This lets us provide the most powerful and optimised experience possible.</p>
					<p>ScorchApp is designed for organisers. It is a cross-platform desktop and browser application that offers total control over managing tournaments. On just one screen, organisers manage their players, pairings, settings, public view, and sync to national databases – including ECF and FIDE. Our priority is customisability – we let you run <i>your</i> tournaments <i>your</i> way.</p>
				</div>
				<div className='buttonSection'>
					<a
						data-aos='zoom-in'
						className={cx('web', 'download', styles.download)}
						href='https://web.scorchapp.co.uk/'
						target='_blank'
						rel='noopener noreferrer'
					>
						Open Web App
					</a>
				</div>
				<div className='buttonSection'>
					{Object.entries({
						windows: {
							name: 'Windows',
							icon: FaWindows
						},
						mac: {
							name: 'Mac',
							icon: FaApple
						},
						ubuntu: {
							name: 'Linux',
							icon: FaLinux
						}
					}).map(([platform, meta], i) => {
						return <a
							key={cx(platform, i)}
							className={cx('platform', 'download')}
							href={`http://repo.scorchapp.co.uk/latest/${platform}.html`}
							target='_blank'
							rel='noopener noreferrer'
							data-aos='zoom-in'
							data-aos-delay={250 * (i + 1)}
						>
							<meta.icon />{meta.name}
						</a>;
					})}
				</div>
				<a data-aos='fade' href='http://repo.scorchapp.co.uk' target='_blank' rel='noopener noreferrer' className={styles.subtitle}>
					<div className='light'>View all downloads</div>
				</a>
			</div>
		</section>
		<section id='features'>
			<div className='wrapper'>
				<h1 data-aos='fade'>Filled with features for organisers</h1>
				<p><b>Efficiency</b> - The primary aim of ScorchChess is to eliminate all the tiresome logistical work for tournament directors and free them to focus on helping players enjoy themselves. It offers organisation without delay.</p>
				<div className={styles.grid}>
					{Object.entries(Features).map(([k, list], i, arr) => {
						return <div
							id={k}
							key={k}
							className={styles.box}
							style={{gridArea: k}}
							data-aos={'fade-' + (isMobile() || i < arr.length / 2 ? 'right' : 'left')}
						>
							<a href={'#' + k} className={styles.title}>
								{capitalise(k)}
							</a>
							{list.map((elem) => {
								return <>
									<FaCheckCircle />
									<div>{elem}</div>
								</>;
							})}
						</div>;
					})}
				</div>
				<div>
					<label>
						See <Link to='/results#results-features'>ScorchResults</Link> for more features about presenting your tournaments.
					</label>
				</div>
			</div>
		</section>
		<section id='uniques'>
			<div className='wrapper'>
				<h1 data-aos='fade'>Selection of some of our unique features</h1>
				<div className='columns'>
					<div className='column-text'>
						<h3 data-aos='fade-left'>The log-on screen</h3>
						<p data-aos='fade-left'>
							We make our work both functional and beautiful. Who says that organising chess tournaments can't be enjoyable for you, too? Experience our state of the art software, with App screens looking aesthetic and intuitive like this.
						</p>
						<p data-aos='fade-left'>We've also built in similar functionalities for sorting player nationalities or pairing pools.</p>
					</div>
					<div data-aos='fade'>
						<Img src='/screenshots/ScorchApp/Home.gif' />
					</div>
				</div>
			</div>
		</section>
		<section id='databases'>
			<div className='wrapper'>
				<div className='columns'>
					<div data-aos='fade'>
						<Img src='/screenshots/ScorchApp/ECF.gif' />
						<Img src='/screenshots/ScorchApp/FIDE.gif' />
						<label>Screenshots from ScorchApp v1.4</label>
					</div>
					<div className='column-text'>
						<h3 data-aos='fade'>Addings players from national databases</h3>
						<p data-aos='fade-right'>It's super easy to add new players straight from the FIDE database. Simply navigate to the 'FIDE' tab in the New Players section and start typing a name! Click and they'll be added to your tournament</p>
						<p data-aos='fade-right'>We also support queries from the ECF database. Get in <Link to='/about#contact'>contact</Link> with the developers if you need support for any other custom databases.</p>
						<p data-aos='fade-right'>Don't worry about adding duplicate players too - we'll alert you if you accidentally make any mistakes! We've also got you covered if you want to search by FIDE/ECF ID and we give you their ratings to help you find the player you're want to add.</p>
					</div>
				</div>
			</div>
		</section>
		<section id='drag-and-drop'>
			<div className='wrapper'>
				<div className='columns'>
					<div className='column-text'>
						<h3 data-aos='fade-left'>Quickly assign players to teams</h3>
						<p data-aos='fade-left'>Have a lot of players? Have a lot of teams? We've created an intuitive interface for you to sort them. Simply:
							<ol>
								<li>Switch into 'Quick Drag & Drop' mode from the dock on the left</li>
								<li>Select the setting at the top to 'Teams'</li>
								<li>Start dragging players from the 'All' or 'Uncategorised' sections on the right to your team category on the left.</li>
							</ol>
						</p>
						<p data-aos='fade-left'>We've also built in similar functionalities for sorting player nationalities or pairing pools.</p>
					</div>
					<div data-aos='fade'>
						<Img src='/screenshots/ScorchApp/Drag-and-Drop.gif' />
						<label>Screenshots from ScorchApp v1.6</label>
					</div>
				</div>
			</div>
		</section>
		<section id='tiebreak-drag'>
			<div className='wrapper'>
				<div className='columns'>
					<div data-aos='fade'>
						<Img src='/screenshots/ScorchApp/Tiebreak.gif' />
						<label>Screenshots from ScorchApp v1.5</label>
					</div>
					<div className='column-text'>
						<h3 data-aos='fade'>Intuitive tie-breaks</h3>
						<p data-aos='fade-left'>We like to make life easy for you. Simply load up settings and 'Drag & Drop' to get your desired order of tie-breaks. We support up to 10!</p>
						<p data-aos='fade-left'>If you're using the latest ScorchApp on Desktop or the web version, you can also introduce a 'null' tiebreak, which basically tells the ranking algorithm to 'stop going through the tiebreaks here'.</p>
					</div>
				</div>
			</div>
		</section>
	</>;
}