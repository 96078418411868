import React, { ReactElement, Dispatch, SetStateAction, useState, useEffect } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { isMobile } from '../utils/auth';
import styles from '../css/titlebar.module.css';
import { useReducer } from 'react';
import { FaBars } from 'react-icons/fa';

const Aliases = {
	desktop: 'ScorchApp',
	results: 'ScorchResults',
	mobile: 'ScorchMobile'
} as {[key: string]: string};

export interface TitlebarProps {
	setID: Dispatch<SetStateAction<string>>
	routes: string[]
}

export default function Titlebar(props: TitlebarProps): ReactElement {

	const [mobileMenu, setMenu] = useState(false);
	const [isScrolled, updateScroll] = useReducer(() => !!window.scrollY, false);

	useEffect(() => {
		window.addEventListener('scroll', updateScroll);
		return () => window.removeEventListener('scroll', updateScroll);
	}, [updateScroll]);

	return <>
		<div className={cx(styles.header, {[styles.scrolled]: isScrolled})}>
			<div className={styles.logoWrapper}>
				<img
					src={process.env.PUBLIC_URL + '/images/favicon.png'}
					className={styles.logo}
					alt='logo'
				/>
			</div>
			{isMobile() ? <div className={styles.menuButton} onClick={() => setMenu(!mobileMenu)}>
				<FaBars />
			</div> : <>
				<div className={styles.nameWrapper}>
					<Link 
						to={{
							pathname: '/',
							state: {
								from: window.location.pathname
							}
						}}
						className={styles.name}>
						<b className='highlighted'>scorch</b>chess
					</Link>
				</div>
				<div className={styles.buttonContainer}>
					{props.routes.map((b, i) => {
						return <React.Fragment key={cx(b, i)}>
							<Link
								className={styles.button}
								to={{
									pathname: '/' + b.toLowerCase(),
									state: {
										from: window.location.pathname
									}
								}}
							>
								{b in Aliases ?
									Aliases[b] :
									b
								}
							</Link>
						</React.Fragment>;
					})}
				</div>
			</>}
		</div>
		<div className={styles.mobileMenu} style={{
			maxHeight: mobileMenu ? '100%' : '0px'
		}}>
			<div style={{ height: '26vh' }} />
			{['Home', ...props.routes].map((b, i) => {
				return <React.Fragment key={cx(b, i)}>
					<Link
						className={styles.button}
						onClick={() => setMenu(false)}
						to={{
							pathname: b === 'Home' ? '/' : '/' + b.toLowerCase(),
							state: {
								from: window.location.pathname
							}
						}}
					>
						{b in Aliases ?
							Aliases[b] :
							b
						}
					</Link>
				</React.Fragment>;
			})}
		</div>
	</>;
}