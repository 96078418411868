import React, { RefObject, useCallback, useReducer, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../css/pricing.module.css';

export default function Pricing() {

	const timeRef = useRef() as RefObject<HTMLInputElement>;
	const pricingRef = useRef() as RefObject<HTMLInputElement>;

	const [total, calculateTotal] = useReducer(() => {
		if (!timeRef.current?.value) return null;
		if (!pricingRef.current?.value) return null;
		let timeSum = (() => {
			let n = Number(timeRef.current.value);
			let periods = Math.ceil(n / 2);
			return Math.min(periods * 48, 200);
		})();
		let people = parseInt(pricingRef.current.value);
		let cut = 0.1;
		let fraction = 1 / (1 - cut);
		let fee = Math.min(timeSum * fraction, 200) / people;
		return `£${fee.toFixed(2)}`;
	}, null as null | string);

	return <>
		<section id='pricing'>
			<div className='wrapper'>
				<a data-aos='fade' href='#pricing' className={styles.title}>
					<h1>Pricing</h1>
				</a>
				<div className={['buttonSection', styles.buttonSection].join(' ')}>
					<div data-aos='fade-down' data-aos-delay='250' className={['download', 'platform', styles.priceButton].join(' ')}>
						<div className='bold'>Free</div>
						<div>£0</div>
						<div>Experience the software</div>
						<div>Unlimited features except<br />public results displays</div>
						<a href='https://web.scorchapp.co.uk' target='_blank' rel='noopener noreferrer'>
							Create account
						</a>
					</div>
					<div data-aos='fade-down' data-aos-delay='500' className={['download', 'platform', styles.priceButton].join(' ')}>
						<div className='bold'>Licensed</div>
						<div>£200 A.R.</div>
						<div>Unlimited tournaments<br />unlimited features</div>
						<div>30-day free trial</div>
						<a href='mailto:aloysius.lip@gmail.com' target='_blank' rel='noopener noreferrer'>Get in contact</a>
					</div>
					<div data-aos='fade-down' data-aos-delay='750' className={['download', 'platform', styles.priceButton].join(' ')}>
						<div className='bold'>Per tournament</div>
						<div>Subject to fees based on time<br />entry fees, and number of players</div>
						<div>Fully-featured for 1 tournament</div>
						<div>Customisable to suit you</div>
						<a href='mailto:aloysius.lip@gmail.com' target='_blank' rel='noopener noreferrer'>Request quote</a>
					</div>
				</div>
			</div>
		</section>
		<section id='quote'>
			<div className='wrapper'>
				<h1 data-aos='fade'>How do we determine your per tournament rate?</h1>
				<p data-aos='fade-left' data-aos-delay='250'>Tournament rates are negotiated on an individual basis. Get in <Link to='/about#contact'>contact</Link> with the creators to explain more about your tournament.</p>
				<p data-aos='fade-left' >In general, rates are determined by*:</p>
				<div  data-aos='fade' className={styles.description}>
					<ul>
						<li>The length of time you'd wish to use the software. We have a standard rate of approximately £48 per 2-month period, up to £200.</li>
						<li>Any entry fees you are charging, in addition to number of competitors. We have a standard rate of around 10% of entry fees, up to £100.</li>
						<li>Other extraneous fees, such as use of our email service or the requirement of additional features.</li>
					</ul>
				</div>
				<p data-aos='fade-left' className='light'>
					* The foregoing is for informational purposes only, does not constitute a binding offer, and is subject to additional terms and conditions and execution of a definitive license agreement.
				</p>
				<p data-aos='fade-left' className='light'>
					<h3>'Break-even' price calculator</h3>
					Based on the above pricing guidelines, how much would I need to charge at a minimum for my tournament?
					<div className={styles.calculator}>
						<label>
							Length of time
							<input type='number' placeholder='Months' ref={timeRef} onChange={calculateTotal} />
						</label>
						<label>
							Number of players
							<input type='number' placeholder='Players' ref={pricingRef} onChange={calculateTotal} />
						</label>
						<label id='answer'>
							Minimum entry fee
							<input type='string' placeholder={'£xx.xx'} value={total ?? undefined} />
						</label>
					</div>
				</p>
				<p data-aos='fade-left' className='light'>
					All features listed in either of our product pages, <Link to='/desktop'>ScorchApp</Link> and <Link to='/results'>ScorchResults</Link>, are included with any licensed plan.
				</p>
			</div>
		</section>
	</>;
}